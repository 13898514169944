<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/orders' }">Orders</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Orders:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Number"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div style="margin-left: 20px">
              <el-select
                v-model="selectedFilter"
                placeholder="Select"
                class="d-block"
                style="height: 40px !important"
              >
                <el-option
                  v-for="item in filterOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <hr />

          <div
            v-loading="loading"
            class="table-responsive mb-5"
          >
            <!-- {{ formattedOrders }} -->
            <data-tables
              :data="formattedOrders"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getOrders"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load orders Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No orders</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Order Number"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  EG0066{{ scope.row.order_id }}
                </template>
              </el-table-column>

              <el-table-column
                label="Amount"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span><strong></strong> {{ scope.row.currency }} {{ formatNumber(scope.row.amount) }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Time of Order"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <strong>{{ formattedDate(scope.row.time_added) }}</strong><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Payment type"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">

                  <span
                    v-if="scope.row.payment_type === 'delivery'"
                    style="color:green"
                  >
                    Delivery
                  </span>
                  <span
                    v-if="scope.row.payment_type === 'cash_on_delivery'"
                    style="color:green"
                  >
                    Cash On Delivery
                  </span>
                  <span
                    v-if="scope.row.payment_type === 'pickup'"
                    style="color:green"
                  >
                    Pickup
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-button
                      @click="showOrderDetailsDialog(scope.row)"
                      type="success"
                      :disabled="false"
                      round
                    >Details</el-button>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <OrderDetailsDialog
      :orderDetailsDialogVisible="orderDetailsDialogVisible"
      :orderData="order_data"
      @closeOrderDetailsDialog="closeOrderDetailsDialog"
    />
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import OrderDetailsDialog from "../components/dialogs/order-details-dialog.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    OrderDetailsDialog,
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      orderDetailsDialogVisible: false,
      orders: [],
      order_data: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,

      selectedFilter: "Show All",
      filterOptions: [
        "Show All",
        "Show Delivery Only",
        "Show Cash On Delivery Only",
        "Show PickUps Only",
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },

    formattedOrders() {
      if (this.selectedFilter === "Show All") {
        return this.orders;
      } else if (this.selectedFilter === "Show Delivery Only") {
        return this.orders.filter((item) => {
          return item.payment_type === "delivery";
        });
      } else if (this.selectedFilter === "Show Cash On Delivery Only") {
        return this.orders.filter((item) => {
          return item.payment_type === "cash_on_delivery";
        });
      } else if (this.selectedFilter === "Show PickUps Only") {
        return this.orders.filter((item) => {
          return item.payment_type === "pickup";
        });
      } else {
        return this.orders;
      }
    },
  },

  mounted() {
    this.getOrders();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    showOrderDetailsDialog(orderData) {
      this.orderDetailsDialogVisible = true;
      this.order_data = orderData;
    },

    closeOrderDetailsDialog() {
      this.orderDetailsDialogVisible = false;
    },

    async getOrders() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        const token = JSON.parse(localStorage.getItem("user"));
        let request = await this.$http.get(`orders`);
        if (
          request.data.success &&
          request.data.message === "ORDERS_FETCHED_SUCCESSFULLY"
        ) {
          this.orders = request.data.orders;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Orders now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteUser(userId) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Order. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`orders/${userId}`);
            if (
              request.data.success &&
              request.data.message === "USER_DELETED_SUCCESSFULLY"
            ) {
              this.getOrders();
              return this.$notify({
                title: "Success",
                message: "Admin User deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            return this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete Admin User, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>